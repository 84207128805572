import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import cx from './ArtefactStaticText.module.css';
import { CopyOutlinedIcon } from 'shared/ui/Icons';
import { Button } from 'antd';
import { removeBraces } from 'shared/lib/removeBraces';
import { ArtefactAnnotations } from '../ArtefactAnnotations';
import { TArtefact, TSelectedImage } from '../../api/types';
import { mdToHtml } from 'shared/lib/mdToHtml';

type TArtefactOptionCardProps = {
	artefact: TArtefact<string>;
	handleImageClick?: (imgData: TSelectedImage) => void;
	name: string;
	regenerateBtn?: React.ReactNode;
	reviewBtn?: React.ReactNode;
};
const ArtefactStaticTextComponent: FC<TArtefactOptionCardProps> = ({
	artefact,
	name,
	regenerateBtn,
	reviewBtn,
	handleImageClick
}) => {
	const { data, metadata } = artefact;
	const [generatedHtml, setGeneratedHtml] = useState<string>('');

	const onCopyClick = useCallback(() => {
		const copyData = typeof data === 'object' ? JSON.stringify(data) : data.toString();
		navigator.clipboard.writeText(copyData);
	}, [data]);

	const createHtmlStr = async (value: string) => {
		const htmlStr = await mdToHtml(value);
		setGeneratedHtml(htmlStr);
	};

	useEffect(() => {
		if (metadata?.annotations) {
			createHtmlStr(
				renderTextWithAnnotations(
					typeof data === 'string' ? data : `\`\`\`${JSON.stringify(data, null, 2)}\`\`\``,
					metadata.annotations
				)
			);
		} else {
			createHtmlStr(typeof data === 'string' ? data : `\`\`\`${JSON.stringify(data, null, 2)}\`\`\``);
		}
	}, [data]);

	const renderTextWithAnnotations = (text: string, annotations: Record<string, string>) => {
		let messageWithAnnotations: string = text;

		for (const key in annotations) {
			const annotation = removeBraces(key) as string;
			const annotationIndex = parseInt(annotation, 10);
			messageWithAnnotations = messageWithAnnotations.replace(annotation, `${annotationIndex}`);
		}
		return messageWithAnnotations;
	};

	const onDiagramClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target && e.target instanceof HTMLImageElement && handleImageClick) {
			handleImageClick({ src: e.target.src, width: e.target.width, height: e.target.height });
		}
	};

	return (
		<div className='rounded-xl p-4'>
			<div className='flex justify-between items-start'>
				<div>
					<p className='text-gray-secondry text-sm mb-3'>{name}</p>
					{artefact.metadata.actionDescription && (
						<p className='text-sm mb-7 opacity-25'>{artefact.metadata.actionDescription}</p>
					)}
				</div>
				<div>
					{regenerateBtn}
					<Button
						type='text'
						shape='circle'
						icon={<CopyOutlinedIcon className='!text-xl opacity-[0.45]' />}
						onClick={onCopyClick}
						className='!text-sm'
					/>
					{reviewBtn}
				</div>
			</div>
			{metadata?.annotations ? (
				<div className={cx.card}>
					<div dangerouslySetInnerHTML={{ __html: generatedHtml }} />
					{!!metadata?.annotations && <ArtefactAnnotations annotations={metadata.annotations} />}
				</div>
			) : (
				<div className={cx.card}>
					<div dangerouslySetInnerHTML={{ __html: generatedHtml }} onClick={onDiagramClick} />
				</div>
			)}
		</div>
	);
};

export const ArtefactStaticText = memo(ArtefactStaticTextComponent);
