import {
	codeBlockPlugin,
	codeMirrorPlugin,
	headingsPlugin,
	listsPlugin,
	linkPlugin,
	markdownShortcutPlugin,
	MDXEditor,
	MDXEditorMethods,
	quotePlugin,
	thematicBreakPlugin
} from '@mdxeditor/editor';
import React, { FC, memo, useState, ReactNode, useRef, useEffect, useCallback } from 'react';
import cx from './ArtefactDynamicText.module.css';
import cn from 'classnames';
import { EditOutlinedIcon, CopyOutlinedIcon } from 'shared/ui/Icons';
import { Button } from 'antd';
import { useTranslate } from 'shared/lib/i18n';
import { TArtefact, TSelectedImage } from '../../api/types';
import { mdToHtml } from 'shared/lib/mdToHtml';

type TArtefactDynamicTextProps = {
	onSave: (title: string, id: string) => void;
	handleImageClick?: (imgData: TSelectedImage) => void;
	artefact: TArtefact<string>;
	name: string;
	blocked: boolean;
	regenerateBtn?: ReactNode;
	reviewBtn?: ReactNode;
};

const ArtefactDynamicTextComponent: FC<TArtefactDynamicTextProps> = ({
	onSave,
	artefact,
	name,
	reviewBtn,
	regenerateBtn,
	blocked,
	handleImageClick
}) => {
	const t = useTranslate();
	const mdxRef = useRef<MDXEditorMethods>(null);
	const { id } = artefact;
	const markdown = artefact.data;
	const [newArtefactData, setNewArtefactData] = useState<string>(markdown);
	const [isDisabled, setIsDisabled] = useState(true);
	const [generatedHtml, setGeneratedHtml] = useState<string>('');
	const handleEdit = () => {
		setIsDisabled((prev) => !prev);
	};

	const createHtmlStr = async (value: any) => {
		const listViewNormalizedValue =
			typeof value === 'string'
				? value.replace(/\n\s*\d+(\.\d+)+\.\s/g, '\n\n$&').replace(/\n(\d+\.) /g, '\n\n$1 ')
				: JSON.stringify(value, null, 2);
		const htmlStr = await mdToHtml(listViewNormalizedValue);
		setGeneratedHtml(htmlStr);
	};

	useEffect(() => {
		createHtmlStr(markdown);
	}, [markdown]);

	const onDiagramClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target && e.target instanceof HTMLImageElement && handleImageClick) {
			handleImageClick({ src: e.target.src, width: e.target.width, height: e.target.height });
		}
	};

	const handleCancel = () => {
		setIsDisabled((prev) => !prev);
		mdxRef.current?.setMarkdown(
			typeof markdown === 'string'
				? markdown.replace(/\n\s*\d+(\.\d+)+\.\s/g, '\n\n$&').replace(/\n(\d+\.) /g, '\n\n$1 ')
				: JSON.stringify(markdown, null, 2)
		);
		setNewArtefactData(
			typeof markdown === 'string'
				? markdown.replace(/\n\s*\d+(\.\d+)+\.\s/g, '\n\n$&').replace(/\n(\d+\.) /g, '\n\n$1 ')
				: JSON.stringify(markdown, null, 2)
		);
	};

	const handleSave = () => {
		setIsDisabled((prev) => !prev);
		onSave(newArtefactData, id);
	};

	const onCopyClick = useCallback(() => {
		const copyData = typeof newArtefactData === 'object' ? JSON.stringify(newArtefactData) : newArtefactData.toString();
		navigator.clipboard.writeText(copyData);
	}, [newArtefactData]);

	return (
		<div className={cn('rounded-xl p-4', { 'border-gray-border border-solid border-[1px]': !isDisabled })}>
			<div className='flex justify-between items-start'>
				<div>
					<p className='text-gray-secondry text-sm'>{name}</p>
					{artefact.metadata.actionDescription && (
						<p className='text-sm mb-7 opacity-25'>{artefact.metadata.actionDescription}</p>
					)}
				</div>
				<div className='dynamic-artefact-controls'>
					{isDisabled && <>{regenerateBtn}</>}
					<Button
						type='text'
						shape='circle'
						icon={<EditOutlinedIcon className='!text-xl' />}
						onClick={handleEdit}
						disabled={!isDisabled || blocked}
					/>
					{isDisabled ? (
						<>
							<Button
								type='text'
								shape='circle'
								icon={<CopyOutlinedIcon className='!text-xl !opacity-[0.45]' />}
								onClick={onCopyClick}
								className='!text-sm'
							/>
							{reviewBtn}
						</>
					) : (
						<span className='opacity-40'>{t('edit_mode')}</span>
					)}
				</div>
			</div>
			<div className={cx.card}>
				{isDisabled ? (
					<div dangerouslySetInnerHTML={{ __html: generatedHtml }} onClick={onDiagramClick} />
				) : (
					<MDXEditor
						className={cn({ 'pointer-events-none': isDisabled })}
						onChange={setNewArtefactData}
						plugins={[
							headingsPlugin(),
							listsPlugin(),
							linkPlugin(),
							quotePlugin(),
							thematicBreakPlugin(),
							markdownShortcutPlugin(),
							codeBlockPlugin({ defaultCodeBlockLanguage: 'plaintext' }),
							codeMirrorPlugin({
								codeBlockLanguages: {
									javascript: 'javascript',
									JavaScript: 'JavaScript',
									js: 'js',
									typescript: 'typescript',
									typeScript: 'typeScript',
									ts: 'ts',
									jsx: 'jsx',
									Jsx: 'Jsx',
									tsx: 'tsx',
									Tsx: 'Tsx',
									css: 'CSS',
									json: 'JSON',
									markdown: 'markdown',
									mermaid: 'mermaid',
									python: 'python',
									Python: 'Python',
									plaintext: 'plaintext',
									java: 'java',
									Java: 'Java'
								}
							})
						]}
						markdown={
							typeof markdown === 'string'
								? markdown.replace(/\n\s*\d+(\.\d+)+\.\s/g, '\n\n$&').replace(/\n(\d+\.) /g, '\n\n$1 ')
								: JSON.stringify(markdown, null, 2)
						}
						ref={mdxRef}
					/>
				)}
			</div>
			{!isDisabled && (
				<div className='flex gap-x-3'>
					<Button type='primary' size='large' onClick={handleSave}>
						{t('save_and_next')}
					</Button>
					<Button size='large' type='text' onClick={() => handleCancel()}>
						{t('cancel')}
					</Button>
				</div>
			)}
		</div>
	);
};

export const ArtefactDynamicText = memo(ArtefactDynamicTextComponent);
